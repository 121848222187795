import { FeatureFlagSetting } from './types'

export const DARK_MODE = 'DARK_MODE'
export const SANDBOX = 'SANDBOX'

export const defaultFlags: FeatureFlagSetting[] = [
  {
    name: DARK_MODE,
    description: '24/09 ~ 24/10',
    active: false,
  },
  {
    name: SANDBOX,
    description: '24/09 ~ 24/10',
    active: false,
  },
]
