import * as React from 'react'
import { useCallback, useEffect, useState } from 'react'

import { User, useAuth0 } from '@auth0/auth0-react'
import { AnyMessage } from '@bufbuild/protobuf'
import {
  Interceptor,
  StreamResponse,
  Transport,
  UnaryResponse,
} from '@connectrpc/connect'
import { TransportProvider } from '@connectrpc/connect-query'
import { createConnectTransport } from '@connectrpc/connect-web'
import { datadogLogs } from '@datadog/browser-logs'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { useTranslation } from 'react-i18next'

import { StreamTransportProvider } from '@enechain/connect-stream-query'
import { SANDBOX, isActiveFeature, queryClient } from '@enechain/jcex-libs'

type ApiClientProviderProps = {
  baseUrl: string
  streamBaseUrl?: string
  children: (
    transport: Transport,
    queryClient: QueryClient,
    user: User | undefined,
  ) => React.ReactNode
}

type ExtendedWindow = Window & {
  dataLayer: Array<Record<string, unknown>>
}

const ApiClientProvider: React.FC<ApiClientProviderProps> = ({
  baseUrl,
  streamBaseUrl,
  children,
}) => {
  const [userLog, setUserLog] = useState<boolean>(true)
  const { user, getAccessTokenSilently } = useAuth0()

  const authInterceptor = useCallback<Interceptor>(
    (next) =>
      async (
        req,
      ): Promise<
        | UnaryResponse<AnyMessage, AnyMessage>
        | StreamResponse<AnyMessage, AnyMessage>
      > => {
        const token = await getAccessTokenSilently().catch((error: unknown) =>
          console.info(error),
        )
        if (token != null && token.length > 0) {
          req.header.set('Authorization', `Bearer ${token}`)
        }
        return next(req)
      },
    [getAccessTokenSilently],
  )

  const requestInterceptor = useCallback<Interceptor>(
    (next) =>
      async (
        req,
      ): Promise<
        | UnaryResponse<AnyMessage, AnyMessage>
        | StreamResponse<AnyMessage, AnyMessage>
      > => {
        // Authorization ヘッダーがない場合はリクエストを中断する
        const result = req.header.get('Authorization')

        if (result == null) {
          const abort = new AbortController()
          abort.abort()

          throw new Error('abort request')
        }

        return next(req)
      },
    [],
  )

  const { i18n } = useTranslation()
  const acceptLanguageInterceptor = useCallback<Interceptor>(
    (next) =>
      async (
        req,
      ): Promise<
        | UnaryResponse<AnyMessage, AnyMessage>
        | StreamResponse<AnyMessage, AnyMessage>
      > => {
        const language = i18n.resolvedLanguage === 'ja' ? 'ja' : 'en'
        req.header.set('Accept-Language', language)
        return next(req)
      },
    [i18n],
  )

  const traceIdInterceptor: Interceptor = (next) => async (req) => {
    const traceId = crypto.randomUUID()
    req.header.set('X-TraceId', traceId)
    return next(req)
  }

  const interceptors = [
    acceptLanguageInterceptor,
    authInterceptor,
    requestInterceptor,
    traceIdInterceptor,
  ]

  const transport = createConnectTransport({
    baseUrl,
    interceptors,
  })

  const notificationTransport = createConnectTransport({
    baseUrl: streamBaseUrl ?? baseUrl,
    interceptors,
  })

  const [_queryClient] = useState(queryClient)

  useEffect(() => {
    if (user === undefined) {
      return
    }
    if (userLog) {
      datadogLogs.setGlobalContextProperty('user_id', user.sub ?? 'Anonymous')

      const windowWithDataLayer = window as unknown as ExtendedWindow
      windowWithDataLayer.dataLayer.push({
        event: 'send_auth0_id',
        auth0_id: user.sub ?? 'Anonymous',
      })

      setUserLog(false)
    }
  }, [user, userLog])

  return (
    <TransportProvider transport={transport}>
      {isActiveFeature({ featureName: SANDBOX, user }) ? (
        <StreamTransportProvider transport={notificationTransport}>
          <QueryClientProvider client={_queryClient}>
            <ReactQueryDevtools initialIsOpen={false} />
            {children(transport, _queryClient, user)}
          </QueryClientProvider>
        </StreamTransportProvider>
      ) : (
        <QueryClientProvider client={_queryClient}>
          <ReactQueryDevtools initialIsOpen={false} />
          {children(transport, _queryClient, user)}
        </QueryClientProvider>
      )}
    </TransportProvider>
  )
}

export default ApiClientProvider
