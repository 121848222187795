import { Transport } from '@connectrpc/connect'
import { QueryClient } from '@tanstack/react-query'
import { createBrowserRouter } from 'react-router-dom'

import {
  ErrorLayout,
  FlagSettingPage,
  NotFound,
  mainLayoutLoader,
} from '@enechain/jcex-ui'

import MainLayout from '~/trader/components/layouts/MainLayout'
import { CarbonIndexPage } from '~/trader/pages/CarbonIndex'
import CreateOrderPage from '~/trader/pages/CreateOrder'
import { createOrderPageLoader } from '~/trader/pages/CreateOrder/loader'
import HistoryListPage from '~/trader/pages/HistoryList'
import { historyListPageLoader } from '~/trader/pages/HistoryList/loader'
import { InstitutionDetailPage } from '~/trader/pages/InstitutionDetail'
import { InstitutionListPage } from '~/trader/pages/InstitutionList'
import JCreditOrderDetailPage from '~/trader/pages/JCreditOrderDetail'
import { jCreditOrderDetailPageLoader } from '~/trader/pages/JCreditOrderDetail/loader'
import NFCOrderDetailPage from '~/trader/pages/NFCOrderDetail'
import { nfcOrderDetailPageLoader } from '~/trader/pages/NFCOrderDetail/loader'
import { NewsletterDetailPage } from '~/trader/pages/NewsletterDetail'
import { NewsletterListPage } from '~/trader/pages/NewsletterList'
import OrderBookListPage from '~/trader/pages/OrderBookList'
import { orderBookListPageLoader } from '~/trader/pages/OrderBookList/loader'
import {
  PATH_CARBON_INDEX,
  PATH_CREATE_ORDER,
  PATH_FLAG_SETTING,
  PATH_HISTORY,
  PATH_HISTORY_JCREDIT_ORDER_DETAIL,
  PATH_HISTORY_NFC_ORDER_DETAIL,
  PATH_INSTITUTION,
  PATH_INSTITUTION_DETAIL,
  PATH_NEWSLETTER,
  PATH_NEWSLETTER_DETAIL,
  PATH_ORDER_BOOK,
} from '~/trader/routes/routePaths'

export const router = (
  transport: Transport,
  queryClient: QueryClient,
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
) =>
  createBrowserRouter([
    {
      element: <MainLayout />,
      errorElement: <ErrorLayout />,
      loader: mainLayoutLoader(transport, queryClient),
      children: [
        {
          path: PATH_ORDER_BOOK.path,
          loader: orderBookListPageLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <OrderBookListPage />,
        },
        {
          path: PATH_HISTORY.path,
          loader: historyListPageLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <HistoryListPage />,
        },
        {
          path: PATH_HISTORY_NFC_ORDER_DETAIL.path,
          loader: nfcOrderDetailPageLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <NFCOrderDetailPage />,
        },
        {
          path: PATH_HISTORY_JCREDIT_ORDER_DETAIL.path,
          loader: jCreditOrderDetailPageLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <JCreditOrderDetailPage />,
        },
        {
          path: PATH_CREATE_ORDER.path,
          loader: createOrderPageLoader(transport, queryClient),
          errorElement: <ErrorLayout />,
          element: <CreateOrderPage />,
        },
        {
          path: PATH_CARBON_INDEX.path,
          errorElement: <ErrorLayout />,
          element: <CarbonIndexPage />,
        },
        {
          path: PATH_FLAG_SETTING.path,
          errorElement: <ErrorLayout />,
          element: <FlagSettingPage />,
        },
        {
          path: PATH_INSTITUTION.path,
          errorElement: <ErrorLayout />,
          element: <InstitutionListPage />,
        },
        {
          path: PATH_INSTITUTION_DETAIL.path,
          errorElement: <ErrorLayout />,
          element: <InstitutionDetailPage />,
        },

        {
          path: PATH_NEWSLETTER.path,
          errorElement: <ErrorLayout />,
          element: <NewsletterListPage />,
        },
        {
          path: PATH_NEWSLETTER_DETAIL.path,
          errorElement: <ErrorLayout />,
          element: <NewsletterDetailPage />,
        },
        {
          path: '*',
          element: <NotFound />,
        },
      ],
    },
  ])
